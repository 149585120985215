/**
 * This file is for managing incident state that is fetched byId
 * - Mainly to be used on the incident details page
 */

import { IncidentApis } from 'data/proxyApi';
import { rsaUserCoordinateSystem } from 'data/store/authStore';
import { selectorFamily } from 'recoil';
import { CoordinatesForDisplay, Incident, IncidentCamera, IncidentLabel } from 'types';
import { getCoordinatesForDisplay, getIncidentLabel } from 'utils';

import { rssUserStationsMap } from '../../stationStore';
import { rsiIncidentLonLatById, rsiUserIncidents } from '../incidentStore';

/**
 * @description Returns the Incident with the given id or token
 * Pulls from rsiUserIncidents, and fetches if not found
 * - NOTE: To just pull without fetching use rsiIncidentById
 */
export const rsiFetchIncidentById = selectorFamily<Incident, number | string>({
  key: 'rsiFetchIncidentById',
  get:
    (
      /** Incident Id or token */
      incidentIdOrToken,
    ) =>
    async ({ get }) => {
      const incidents = get(rsiUserIncidents);

      const incident = incidents?.find((incident) => {
        return incident.id.toString() === incidentIdOrToken.toString();
      });

      if (incident) {
        return incident;
      }

      try {
        const response = await IncidentApis.apiGetIncidentById(incidentIdOrToken);

        return response;
      } catch (err) {
        console.error(err);

        return null;
      }
    },
});

/**
 * @description Returns the cameras for the incident with the given id
 * - Will filter out any cameras the user does not have access to
 * - Will filter out any cameras with no mark
 */
export const rsiIncidentCamerasById = selectorFamily<IncidentCamera[], number>({
  key: 'rsiIncidentCamerasById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiFetchIncidentById(incidentId));
      const userStationsMap = get(rssUserStationsMap);

      return (
        incident?.cameras?.filter((camera) => !!camera.mark).filter((camera) => userStationsMap[camera?.id]?.name) || []
      );
    },
});

/**
 * Returns the primary incident camera for the incident with the given id
 */
export const rsiPrimaryIncidentCameraById = selectorFamily<IncidentCamera, number>({
  key: 'rsiPrimaryIncidentCameraById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiFetchIncidentById(incidentId));
      const incidentCameras = get(rsiIncidentCamerasById(incidentId));
      const primaryId = incident?.primary;

      return incidentCameras.find((camera) => camera.id === primaryId) || null;
    },
});

/**
 * Returns the primary incident camera's error in Km for the incident with the given id
 */
export const rsiPrimaryIncidentCameraGeoLocationErrorKmById = selectorFamily<number, number>({
  key: 'rsiPrimaryIncidentCameraById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incidentCamera = get(rsiPrimaryIncidentCameraById(incidentId));
      const nearDistanceKm = incidentCamera?.geolocation?.nearDistanceKm;
      const farDistanceKm = incidentCamera?.geolocation?.farDistanceKm;
      if (nearDistanceKm !== undefined && farDistanceKm !== undefined) {
        const errorKm = (farDistanceKm - nearDistanceKm) / 2;

        return errorKm;
      }

      return null;
    },
});

/**
 * Returns the geolocation centroid for the primary incident camera
 * @note the centroid is the estimates location of the incident
 * when using single station incident location range (SSILR) estimation
 */
export const rsiPrimaryCentroidById = selectorFamily<CoordinatesForDisplay[], number>({
  key: 'rsiPrimaryCentroidById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incidentCamera = get(rsiPrimaryIncidentCameraById(incidentId));
      const centroid = incidentCamera?.geolocation?.centroid;
      const coordinateSystem = get(rsaUserCoordinateSystem);

      return getCoordinatesForDisplay(centroid, coordinateSystem);
    },
});

/**
 * @description Returns all available coordinates for the incident
 * - Will return GDA2020 if that GCS is available for any user org
 */
export const rsiIncidentTriangulatedCoordinatesById = selectorFamily<CoordinatesForDisplay[], number>({
  key: 'rsiIncidentTriangulatedCoordinatesById',
  get:
    (incidentId) =>
    ({ get }) => {
      const lonLat = get(rsiIncidentLonLatById(incidentId));
      const coordinateSystem = get(rsaUserCoordinateSystem);

      return getCoordinatesForDisplay(lonLat, coordinateSystem);
    },
});

/**
 * @description Returns the cameras for the incident with the given id
 * - Will filter out any cameras the user does not have access to
 * - Will filter out any cameras with no mark
 */
export const rsiFetchIncidentLabelById = selectorFamily<IncidentLabel, number>({
  key: 'rsiIncidentCamerasById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiFetchIncidentById(incidentId));

      return getIncidentLabel(incident);
    },
});
