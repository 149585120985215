import { GeoLocation } from 'types';

export const scaleNumberTo = (num: number, decimal = 3): number => parseFloat(num.toFixed(decimal));

export const m2ft = (meter: number): number => Math.ceil(meter * 3.28084);

export const km2mi = (km: number): string => (km * 0.621371).toFixed(1);

export const metersToKilometers = (meters: number): number => Math.round((meters / 1000) * 100) / 100;
export const metersToMiles = (meters: number): number => Math.round((meters * 100) / 1609) / 100;

/**
 * Returns a formatted string for a distance, with units
 * @param units 'si' or 'iu'
 * @param distance the distance in meters, feet, km, or mi
 * @param isLargeUnit whether the unit is in km and mi rather than ft and meters
 * @example 1.2 km
 */
export const getDistance = (units = 'si', distance: number, isLargeUnit = true): string => {
  if (units === 'iu') {
    return isLargeUnit ? `${km2mi(distance)} mi` : `${m2ft(distance)} ft`;
  }

  return isLargeUnit ? `${distance.toFixed(1)} km` : `${Math.ceil(distance)} m`;
};

/**
 * Returns a formatted string for an estimated SSILR range
 * @example ~1.4 km to ~2.3 km
 * @example at least ~0.9 mi
 */
export const getFormattedDistanceRange = (units = 'si', geolocation: GeoLocation): string => {
  const { nearDistanceKm, farDistanceKm } = geolocation;
  const nearDistanceString = units === 'iu' ? `~${km2mi(nearDistanceKm)}` : `~${nearDistanceKm.toFixed(1)}`;
  const unit = units === 'iu' ? 'mi' : 'km';

  if (farDistanceKm) {
    const farDistanceString = units === 'iu' ? `~${km2mi(farDistanceKm)}` : `~${farDistanceKm.toFixed(1)}`;

    return `${nearDistanceString} to ${farDistanceString} ${unit}`;
  }

  return `at least ${nearDistanceString} ${unit}`;
};

/**
 * Returns a formatted string for an estimated SSILR range
 * @example The incident is estimated to be between ~1.4 km and ~2.3 km
 * @example The incident is estimated to be at least 0.9 mi
 */
export const getFormattedDistanceRangeVerbose = (units = 'si', geolocation: GeoLocation): string => {
  const { nearDistanceKm, farDistanceKm } = geolocation;
  const nearDistanceString = units === 'iu' ? `~${km2mi(nearDistanceKm)}` : `~${nearDistanceKm.toFixed(1)}`;
  const unit = units === 'iu' ? 'miles' : 'kilometers';

  if (farDistanceKm) {
    const farDistanceString = units === 'iu' ? `~${km2mi(farDistanceKm)}` : `~${farDistanceKm.toFixed(1)}`;

    return `The incident is estimated to be between ${nearDistanceString} and ${farDistanceString} ${unit}`;
  }

  return `The incident is estimated to be farther than ${nearDistanceString} ${unit}`;
};

export const roundNumber = (num: number, dec: number): number => {
  return Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
};

export const roundNumbers = (nums: number[], dec: number): number[] => {
  return nums.map((n: number) => roundNumber(n, dec));
};

export const roundNumbers2D = (nums2D: number[][], dec: number): number[][] => {
  return nums2D.map((nums: number[]) => roundNumbers(nums, dec));
};

/** Returns a base 16 string from a base 10 number */
export const getTwoDigitHexString = (decimal: number) => {
  const integerDecimal = Math.floor(decimal);
  if (integerDecimal < 16) {
    return '0' + integerDecimal.toString(16);
  }

  return integerDecimal.toString(16);
};
