import { INCIDENT_LABEL, IncidentBarColors, IncidentLabel } from 'types';

import { Theme } from '@mui/material/styles';

/** Returns the colors of incident features for the map */
export const getIncidentColors = (theme: Theme, label: IncidentLabel, isSatelliteView: boolean): IncidentBarColors => {
  const accessoryColors = {
    grey: {
      color: isSatelliteView ? theme.palette.white['100Opacity'] : theme.palette.greys.dark,
      opacity: isSatelliteView ? 0.4 : 0.2,
    },
    dot: {
      color: isSatelliteView ? theme.palette.white['100Opacity'] : theme.palette.greys.dark,
      opacity: 1,
    },
  };
  switch (label) {
    case INCIDENT_LABEL.CONFIRMED:
      return {
        ...accessoryColors,
        primary: {
          color: theme.palette.secondaryExtra.medium,
          opacity: 0.6,
        },
        border: {
          color: theme.palette.secondary.dark,
          opacity: 1,
        },
        pointedTip: {
          color: isSatelliteView ? theme.palette.secondary.light : theme.palette.secondary.dark,
          opacity: 1,
        },
        chevron: {
          color: isSatelliteView ? theme.palette.secondary.light : theme.palette.secondary.dark,
          opacity: 1,
        },
        stroke: {
          color: isSatelliteView ? theme.palette.white['100Opacity'] : undefined,
          opacity: isSatelliteView ? 1 : undefined,
        },
      };
    case INCIDENT_LABEL.POSSIBLE:
      return {
        ...accessoryColors,
        primary: {
          color: theme.palette.primaryExtra.medium,
          opacity: 0.6,
        },
        border: {
          color: theme.palette.primary.dark,
          opacity: 1,
        },
        pointedTip: {
          color: isSatelliteView ? theme.palette.primaryExtra.medium : theme.palette.primary.dark,
          opacity: 1,
        },
        chevron: {
          color: isSatelliteView ? theme.palette.primaryExtra.medium : theme.palette.primary.main,
          opacity: 1,
        },
        stroke: {
          color: isSatelliteView ? theme.palette.white['100Opacity'] : undefined,
          opacity: isSatelliteView ? 1 : undefined,
        },
      };
    case INCIDENT_LABEL.CLOSED:
      return {
        ...accessoryColors,
        primary: {
          color: theme.palette.greys.lighter,
          opacity: 0.6,
        },
        border: {
          color: theme.palette.greys.dark,
          opacity: 1,
        },
        pointedTip: {
          color: isSatelliteView ? theme.palette.white['100Opacity'] : theme.palette.greys.dark,
          opacity: 1,
        },
        chevron: {
          color: isSatelliteView ? theme.palette.white['100Opacity'] : theme.palette.greys.dark,
          opacity: 1,
        },
        stroke: {
          color: isSatelliteView ? theme.palette.greys.dark : undefined,
          opacity: isSatelliteView ? 1 : undefined,
        },
      };
    case INCIDENT_LABEL.DISMISSED:
      return {
        ...accessoryColors,
        primary: {
          color: theme.palette.greys.lighter,
          opacity: 0.6,
        },
        border: {
          color: theme.palette.greys.dark,
          opacity: 1,
        },
        pointedTip: {
          color: isSatelliteView ? theme.palette.white['100Opacity'] : theme.palette.greys.dark,
          opacity: 1,
        },
        chevron: {
          color: isSatelliteView ? theme.palette.white['100Opacity'] : theme.palette.greys.dark,
          opacity: 1,
        },
        stroke: {
          color: isSatelliteView ? theme.palette.greys.dark : undefined,
          opacity: isSatelliteView ? 1 : undefined,
        },
      };
    default:
      return {
        ...accessoryColors,
        primary: {
          color: theme.palette.greys.lighter,
          opacity: 0.6,
        },
        border: {
          color: theme.palette.greys.dark,
          opacity: 1,
        },
        pointedTip: {
          color: isSatelliteView ? theme.palette.white['100Opacity'] : theme.palette.greys.dark,
          opacity: 1,
        },
        chevron: {
          color: isSatelliteView ? theme.palette.white['100Opacity'] : theme.palette.greys.dark,
          opacity: 1,
        },
        stroke: {
          color: isSatelliteView ? theme.palette.greys.dark : undefined,
          opacity: isSatelliteView ? 1 : undefined,
        },
      };
  }
};
