import axios, { AxiosBasicCredentials, AxiosInstance, AxiosResponse } from 'axios';
import { authApi, publicApi } from 'config/api';
import { SECRET_ANONYMOUS } from 'config/base';
import { API_HEADER_MFA } from 'config/constants';
import MESSAGES from 'config/messages';
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';
import {
  AccountSettingsPayload,
  ApiGetSendSmsParams,
  ApiPostVerifySmsParams,
  ApiResponse,
  HttpStatusCode,
  InviteResponseData,
  LoginFormValues,
  OrgPermission,
  PanoUser,
  PasswordPolicy,
  RegisterFormValues,
  RegisterTokenResponseData,
  ResetTokenResponseData,
  Role,
  ValidationErrorResponse,
} from 'types';
import { trimStringValues } from 'utils';

interface SendInvitationPayload {
  email: string;
  org: string;
  role: Role;
  orgPermissions?: OrgPermission[];
}

export default class AuthApi {
  api: AxiosInstance;

  constructor() {
    this.init();
  }

  async init() {
    const api = authApi();
    this.api = api;
  }

  setAxiosInstanceApi(axiosInstance: AxiosInstance) {
    this.api = axiosInstance;
  }

  getAnonymousUser(bearer: string): PanoUser {
    return {
      id: SECRET_ANONYMOUS,
      email: '',
      firstName: SECRET_ANONYMOUS,
      bearer,
      version: 1,
    };
  }

  // Get all organizations (for invite)
  async apiGetAllOrganizations(): Promise<string[]> {
    try {
      const res: AxiosResponse = await publicApi.get('orgs');
      const orgs: string[] = res?.data ? _sortBy(res?.data) : [];

      return orgs;
    } catch (err) {
      throw new Error(`[Pano] API Error: ${JSON.stringify(err)}`);
    }
  }

  // Send invitation
  async apiPostSendInvitation(
    email: string,
    selectedOrganization: string,
    role = Role.User,
    orgPermissions: OrgPermission[],
  ): Promise<ApiResponse<InviteResponseData>> {
    try {
      const payload: SendInvitationPayload = {
        email: email.toLowerCase().trim(),
        org: selectedOrganization,
        role,
      };
      if (orgPermissions && orgPermissions.length > 0) {
        payload.orgPermissions = orgPermissions;
      }
      const res: AxiosResponse = await this.api.post('/register', payload, { shouldSkipErrorHandling: true });

      return {
        status: res?.status,
        data: res?.data,
      };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return {
          status: err?.response?.status,
          message: err?.response?.data?.message,
        };
      }

      return {
        status: 500,
        message: MESSAGES.INVITE_FAILED,
      };
    }
  }

  // Verify register token
  async apiGetRegisterAccountByToken(token: string): Promise<ApiResponse<RegisterTokenResponseData>> {
    try {
      const res = await publicApi.get(`/register/${token}`, { shouldSkipErrorHandling: true });

      return res;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return {
          status: err?.response?.status,
          message: err?.response?.data?.message,
        };
      }

      return null;
    }
  }

  // Register an account
  async apiPostRegister(
    token: string,
    userInfo: RegisterFormValues,
    isSubscriber = false,
  ): Promise<ApiResponse<RegisterTokenResponseData | ValidationErrorResponse>> {
    delete userInfo.acceptTos;
    try {
      const res: AxiosResponse = await publicApi.post(
        `/register/${token}`,
        {
          ...trimStringValues(userInfo),
          ...(isSubscriber ? {} : { password: userInfo.password || '' }),
          tosMethod: 'web', // @todo can be `mobile` when we have mobile app.
        },
        { shouldSkipErrorHandling: true },
      );

      return {
        status: res?.status,
        data: res?.data || {},
      };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return { status: err?.response?.status, data: err?.response?.data };
      }

      return null;
    }
  }

  // Login a user
  async apiGetLogin({ email, password, acceptTos = false, smsCode }: LoginFormValues): Promise<ApiResponse<PanoUser>> {
    const headers: Record<string, string> = {};
    if (acceptTos) {
      headers['X-TOS-Method'] = 'web'; // @todo always 'web' now.
    }
    if (smsCode) {
      headers[API_HEADER_MFA] = smsCode;
    }
    try {
      return await publicApi({
        method: 'get',
        url: '/account',
        auth: {
          username: email.toLowerCase(),
          password,
        },
        headers,
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return { message: err?.response?.data.message, ...err?.response };
      }

      return { status: HttpStatusCode.InternalServerError, data: null };
    }
  }

  /**
   * This function is meant for getting the user account object during SSO
   * - /acs will return a token.
   * - That token will be used to get the user's account
   * - Once we have the account, we can grant access to the application
   * @returns The user profile with bearer token.
   */
  async apiGetPanoAccountForSSO({
    token,
  }: {
    /** The bearer token returned from /acs for SSO */
    token: string;
  }): Promise<ApiResponse<PanoUser>> {
    try {
      return await publicApi({
        method: 'get',
        url: '/account',
        headers: { Authorization: `Bearer ${token}` },
        shouldSkipErrorHandling: true,
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return { message: err?.response?.data.message, ...err?.response };
      }

      return { status: HttpStatusCode.InternalServerError, data: null };
    }
  }

  /**
   * Update the user's account preferences
   * @param existingUser - The existing Pano User
   * @param preference - The users new preferences settings
   */
  async apiPutUpdateAccountPreferences(existingUser: PanoUser, preference: AccountSettingsPayload): Promise<PanoUser> {
    try {
      const password: string = existingUser.password || '';
      const userPayload: PanoUser = { ...existingUser };
      delete userPayload.password;
      const auth: AxiosBasicCredentials = password
        ? {
            username: existingUser.email.toLowerCase(),
            password: password,
          }
        : null;
      const res: AxiosResponse = await this.api({
        url: '/account',
        method: 'put',
        data: {
          ...userPayload,
          ...preference,
        },
        auth,
        shouldSkipErrorHandling: true,
      });
      if (res.status === 200) {
        return res?.data;
      }

      return null;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const userNewVersion: PanoUser = e?.response?.data;
        // If account has been changed,
        if (userNewVersion && userNewVersion.bearer && userNewVersion.id && !_isEqual(userNewVersion, existingUser)) {
          return userNewVersion;
        }
      }

      return null;
    }
  }

  /**
   * Update the user's account Terms Of Service (TOS)
   * @param existingUser - The existing Pano User
   */
  async apiPutUpdateAccountTOS(existingUser: PanoUser): Promise<PanoUser> {
    try {
      const { data } = await this.api.put(`/account`, {
        ...existingUser,
        tosMethod: 'web',
      });

      return data;
    } catch (err) {
      console.error('[Pano] API Error Update TOS', err);

      return null;
    }
  }

  // Check token to reset password
  async apiPostSendResetEmail(email: string): Promise<ApiResponse<never>> {
    try {
      const res: AxiosResponse = await publicApi.post('/reset', {
        email: email.toLowerCase().trim(),
      });

      return { status: res?.status, message: res?.statusText };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return err?.response?.data;
      }

      return null;
    }
  }

  // Check token to reset password
  async apiGetResetEmailByToken(token: string): Promise<ApiResponse<ResetTokenResponseData>> {
    try {
      return await publicApi.get(`/reset/${token}`);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return err?.response;
      }

      return null;
    }
  }

  // Reset password
  async apiPostResetPassword(token: string, password: string): Promise<ApiResponse<never | ValidationErrorResponse>> {
    try {
      const res: AxiosResponse = await publicApi.post(`/reset/${token}`, {
        password: password,
      });

      return { status: res?.status, message: res?.statusText };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return { message: err?.response?.data.message, status: err?.response?.status, data: err?.response?.data };
      }

      return null;
    }
  }

  // Logout the current user
  async apiGetLogout(): Promise<boolean> {
    try {
      const { status } = await this.api.get('/logout');

      return status === 204;
    } catch (e) {
      return false;
    }
  }

  async apiGetRefreshAccount(): Promise<PanoUser> {
    try {
      const res: AxiosResponse = await this.api.get('/account', { shouldSkipErrorHandling: true });
      if (res.status === 200) {
        return res.data;
      }

      return null;
    } catch (e) {
      console.error('Failed to refresh account', e);

      return null;
    }
  }

  /**
   * Send SMS to user
   * @param phone
   * @param useAuthedApi
   * @param token
   * @param isPendingMfaSetup
   * @param password
   * @param email
   */
  async apiGetSendSms({
    phone = '',
    useAuthedApi = false,
    token = '',
    isPendingMfaSetup = false,
    password = '',
    email = '',
  }: ApiGetSendSmsParams): Promise<ApiResponse<PanoUser>> {
    try {
      const api: AxiosInstance = useAuthedApi ? this.api : publicApi;
      const auth: AxiosBasicCredentials = password
        ? {
            username: email.toLowerCase(),
            password: password,
          }
        : null;
      const unauthEndpoint: string = isPendingMfaSetup ? `/account/sm/${phone}` : `/register/${token}/sm/${phone}`;

      const { data, status } = await api({
        url: useAuthedApi ? `/account/sm/${phone}` : unauthEndpoint,
        method: 'get',
        auth,
        shouldSkipErrorHandling: true,
      });
      const success: boolean = status >= HttpStatusCode.Ok && status < HttpStatusCode.Redirect;

      return {
        status,
        data: success && !useAuthedApi ? data : null,
      };
    } catch (e) {
      return null;
    }
  }

  /**
   * Verify SMS code
   * @param code
   * @param useAuthedApi
   * @param token
   * @param password
   * @param email
   */
  async apiPostVerifySmsCode({
    code = '',
    useAuthedApi = false,
    token = '',
    password = '',
    email = '',
  }: ApiPostVerifySmsParams): Promise<ApiResponse<PanoUser>> {
    try {
      let res: AxiosResponse = null;
      if (password) {
        const auth: AxiosBasicCredentials = {
          username: email.toLowerCase(),
          password: password,
        };
        res = await publicApi({
          url: '/account/sm',
          method: 'post',
          data: {
            code: String(code).trim(),
          },
          auth,
          shouldSkipErrorHandling: true,
        });
      } else {
        const api: AxiosInstance = useAuthedApi ? this.api : publicApi;
        res = await api.post(
          useAuthedApi ? '/account/sm' : `/register/${token}/sm`,
          {
            code: String(code).trim(),
          },
          { shouldSkipErrorHandling: true },
        );
      }

      const { status, data } = res || {};
      const success: boolean = status === HttpStatusCode.Ok;

      return {
        status,
        success,
        data: success ? data : null,
      };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return err?.response;
      }

      return { status: HttpStatusCode.InternalServerError };
    }
  }

  /**
   * Validate a password against the backend policies.
   *
   * @param password the password to validate.
   * @returns an `ApiResponse` with status of `400` and `ValidationErrorResponse` data payload if the password is invalid;
   *          otherewise, an `ApiResponse` with status of `204` and no data payload.
   */
  async apiPostPasswordValidate(password: string): Promise<ApiResponse<never | ValidationErrorResponse>> {
    try {
      const res: AxiosResponse = await publicApi.post('/password:validate', {
        password: password,
      });

      return { status: res?.status, message: res?.statusText };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return { message: err?.response?.data.message, status: err?.response?.status, data: err?.response?.data };
      }

      return null;
    }
  }

  /**
   * Get the backend password policy.
   * @returns an `ApiResponse` with status of `200` and `PasswordPolicy` data payload.
   */
  async apiGetPasswordPolicy(): Promise<ApiResponse<PasswordPolicy>> {
    try {
      const res: AxiosResponse = await publicApi.get('/password-policy');

      return { status: res?.status, data: res?.data };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return { message: err?.response?.data.message, status: err?.response?.status };
      }

      return null;
    }
  }
}
