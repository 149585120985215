import { HEADER_HEIGHT } from 'config/constants';

import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  header: {
    height: HEADER_HEIGHT,
  },
  fixed: {
    position: 'sticky',
    top: '0',
    left: '0',
    right: '0',
    zIndex: 10,
  },
  content: (theme: Theme) => ({
    margin: '0 auto',
    padding: '0 12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    backgroundColor: theme.palette.white.main,
  }),
  'header-button': (theme: Theme) => ({
    whiteSpace: 'nowrap',
    marginRight: 1,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 2,
      paddingRight: 2,
      whiteSpace: 'unset',
      lineHeight: 'normal',
    },
  }),
  orgPanoBg: {
    backgroundColor: 'success.main',
  },
  orgInsuranceBg: {
    backgroundColor: 'primary.main',
  },
  orgOwnerBg: (theme: Theme) => ({
    backgroundColor: theme.palette.headers.landowners,
  }),
  orgUtilityBg: {
    backgroundColor: 'primary.dark',
  },
  orgGovernmentBg: (theme: Theme) => ({
    backgroundColor: theme.palette.greys.light,
  }),
  'trailing-icons-wrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
  },
  locIcon: (theme: Theme) => ({
    marginRight: 1,
    color: theme.palette.white.main,
  }),
  orgLogo: {
    display: 'flex',
    marginLeft: 1,
  },
  showBorder: (theme: Theme) => ({
    boxShadow: `0 1px 1px ${theme.palette.greys.extraLight} `,
  }),
};

export default styles;
