import { MapAsset, NearbyFeature } from 'types';

/**
 * @TODO - REMOVE IN https://panoai.atlassian.net/browse/RD-6262
 * The shape of the new nearbyfeatures response is different from geosearch
 * This adapts that shape to the old geosearch shape
 * Longer term we'll rip this to avoid the adaption.
 */
export const transformToOldGeoSearchShape = (newMapAssets: NearbyFeature[]) => {
  return newMapAssets.map(
    (mapAsset) =>
      ({
        collectionName: mapAsset.collectionName,
        type: mapAsset.feature.type,
        geometry: mapAsset.feature.geometry,
        properties: {
          ...mapAsset.feature.properties,
          datasetName: mapAsset.collectionName,
          nearestPoint: {
            geometry: {
              coordinates: mapAsset.nearestPoint.coordinates,
            },
            type: mapAsset.nearestPoint.type,
          },
          MinDistanceInMeters: mapAsset.minDistance,
        },
      } as unknown as MapAsset),
  );
};
