import { INCIDENT_LABEL, IncidentLabel, MapAssetDataSet, Ptz } from 'types';

export const INPUT_DEBOUNCE_TIMEOUT = 350;
/** The max number of incidents to return from the incident API */
export const INCIDENT_LIMIT = 1000;

export const API_TRACE_HEADER = 'correlation-id';
/** The header to indicate MFA status */
export const API_HEADER_MFA = 'x-pano-mfa';
export const API_TIMEOUT = 30000; // Timeout 30 seconds for all API calls.
/** @todo If cache size goes beyond 1000, show warning now. */
export const API_CACHE_SIZE = 1000;
export const LOOP_INTERVAL_60S = 60_000; // 60 seconds loop interval
export const LOOP_INTERVAL_5M = 300_000; // 5 minutes loop interval
export const OZ_SESSION_LIVE_MAX_MS = Number(localStorage?.getItem('OZ_SESSION_LIVE_MAX_MS')) || 10 * 60 * 1000; // An OZ session lasts only for max 10 minutes
/** Seconds until a station is considered offline if no images are recieved, currently 10 minutes */
export const DEFAULT_PLAYER_PERIOD: number = 10 * 60;
/** Earliest possible date for an incident
 * @jira https://panoai.atlassian.net/browse/RD-504 */
export const MIN_INCIDENT_DATE: Date = new Date(1000000000000); // 09/09/2001
/** Lowest possiple incident id */
export const MIN_INCIDENT_ID = 1;
export const USER_FIELD = 'PANO_USER';
/** The key used for localstorage feature flag overrides */
export const FLAG_OVERRIDE_KEY = 'PANO_FLAGS';
export const EMPTY_FUNCTION = (): void => {};
/** the distance between the start and end of a gradient section of the incident bar */
export const INCIDENT_BAR_GRADIENT_METERS = 250;
/** the length of an uncalibrated incident bar */
export const INCIDENT_BAR_UNCALIBRATED_LENGTH = 20000;
/** the maximum number of chevrons rendered for an uncalibrated incident */
export const MAX_UNCALIBRATED_CHEVRONS_COUNT = 40;
/** factor to scale the width of the incident to the border and chevrons, 15 = 1/15  */
export const INCIDENT_BAR_BORDER_WIDTH_SCALE = 15;
/** the ratio of the hypotenuse to either of the triangle sides */
export const ISOCOLES_RIGHT_TRIANGLE_HYPOTENUSE_RATIO = Math.sqrt(2);
export const METERS_IN_KM = 1000;
/** the width of the incident bar that a dot's width will be, 0.1 is 10% */
export const INCIDENT_BAR_DOT_WIDTH_SCALE = 0.1;
/** the length of the solid colored bar when an incident has an infinite end */
export const INCIDENT_INFINITE_END_SOLID_BAR_METERS = 5000;
/** the default station visibility if none is provided */
export const DEFAULT_VISIBILITY_KM = 20;
export const CLICKABLE_INCIDENT_FEATURE_ID = 'clickable-incident-feature';

export const HEADER_HEIGHT = 48;

export const INCIDENT_FILTER_QUERY_FIELD = 'incidentFilterQuery';
export const INCIDENT_OCV_FILTER_QUERY_FIELD = 'incidentOCVFilterQuery';
export const INITIAL_FILTERS: IncidentLabel[] = [
  INCIDENT_LABEL.POSSIBLE,
  INCIDENT_LABEL.CLOSED,
  INCIDENT_LABEL.DISMISSED,
  INCIDENT_LABEL.PRESCRIBED,
  INCIDENT_LABEL.CONFIRMED,
];
export const INCIDENT_ORDERS: { [x: string]: number } = {
  [INCIDENT_LABEL.DISMISSED]: 0,
  [INCIDENT_LABEL.CLOSED]: 1,
  [INCIDENT_LABEL.PRESCRIBED]: 2,
  [INCIDENT_LABEL.CONFIRMED]: 3,
  [INCIDENT_LABEL.POSSIBLE]: 4,
};

export const DEFAULT_PAN_TILT_ZOOM: Ptz = {
  pan: 0,
  tilt: 0,
  zoom: 1,
};

export const PGE_ASSET_DATASETS: MapAssetDataSet[] = [
  MapAssetDataSet.PgeOverheadMainlines,
  MapAssetDataSet.PgeOverheadTaplines,
  MapAssetDataSet.PgeUndergroundMainlines,
  MapAssetDataSet.PgeUndergroundTaplines,
  MapAssetDataSet.PgeLocation,
  MapAssetDataSet.PgeSubstation,
  MapAssetDataSet.PgeHydroPlant,
];
